"use client";

import { PathName } from "@/routers/types";
import { useRouter } from "next/navigation";
import React, { FC } from "react";
import { useSearchForm } from "./SearchFormContext";
import { Route } from "next";

interface Props {
  href?: PathName;
}

const ButtonSubmit: FC<Props> = ({ href = "/listing-product-map" }) => {
  const router = useRouter();
  const { lat, lng, totalGuests, duration, category, eventtype, date, location, name, email, phone } = useSearchForm();

  const handleSubmit = () => {
    if (lat === null || lng === null) {
      alert("Veuillez sélectionner un lieu de départ");
      return;
    }

    let minDuration = 0;
    let maxDuration = 120;

    switch (duration) {
      case '20 min':
        maxDuration = 120;
        break;
      case '30 min':
        minDuration = 0;
        maxDuration = 120;
        break;
      case '1h et plus':
        minDuration = 0;
        break;
    }

    let url;
    if (href === "/listing-flights") {
      url = `${href}?location=${location}&lat=${lat}&lng=${lng}&date=${date}&eventtype=${eventtype}&name=${name}&email=${email}&phone=${phone}&passengers=${totalGuests}`;
    } else {
      url = `${href}?minPrice=0&maxPrice=1000&minDuration=${minDuration}&maxDuration=${maxDuration}&maxGuests=${totalGuests}&lat=${lat}&lng=${lng}&category=${encodeURIComponent(category)}&location=${location}`;
    }
    
    router.push(url as Route);
  };

  return (
    <button
      onClick={handleSubmit}
      type="button"
      className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
    >
      <span className="mr-3 md:hidden">Rechercher</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </button>
  );
};

export default ButtonSubmit;