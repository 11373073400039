import React, { FC, useState } from "react";

interface Props {
  value?: string; // l'heure en format "HH:mm"
  onChange?: (e: string) => void; // callback pour transmettre la nouvelle valeur de l'heure
}

const DatePickerCustomTime: FC<Props> = ({ onChange, value = "12:00" }) => {
  const [time, setTime] = useState(value); // gérer l'état interne de l'heure

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = e.target.value;
    setTime(newTime); // mettre à jour l'état local
    onChange?.(newTime); // transmettre la nouvelle heure au parent
  };

  return (
    <div className="flex items-center space-x-3">
      <label className="text-neutral-500 dark:text-neutral-400 text-sm">
        
      </label>
      <input
        type="time" // spécifie que c'est un champ d'heure
        value={time}
        onChange={handleTimeChange}
        className="w-26 h-10 text-lg font-semibold text-neutral-900 dark:text-neutral-100 bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500"
      />
    </div>
  );
};

export default DatePickerCustomTime;
